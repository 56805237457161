<template>
  <v-dialog
    class="image-widget image-gallery-widget"
    v-model="dialogImageGalleryWidget"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>


      <v-card>
        <v-card-title
          class="widget__heading"
        >
          Image Gallery
        </v-card-title>

        <v-card-text>

          <ImageUploader :title="'Image'" :widthOptionEnabled="false" @update="addImage($event)"/>

          <section v-if="imageUploadSession.gallery.length" class="image-gallery-widget__simple-listings">
            <draggable
              v-model="imageUploadSession.gallery"
              :options="{
                ghostClass: 'ghost',
                dragClass: 'sortable-drag'
              }"
              @start="drag=true"
              @end="drag=false">
              <transition-group name="list-complete">
                <WidgetImageGalleryRows v-for="(item, index) in imageUploadSession.gallery" :item="item" :key="item.mediaLibraryID" @delete="deleteGalleryItem(index)"></WidgetImageGalleryRows>
              </transition-group>
          </draggable>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="accent"
                outline
                flat
                @click="postImages(); dialogImageGalleryWidget = null"
              >
                Post
              </v-btn>
            </v-card-actions>
          </section>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import ImageUploader from "@/components/Library/ImageUploader"
import WidgetImageGalleryRows from "@/components/Library/WidgetImageGalleryRows"

export default {
  name: "ImageWidget",

  components: {
    draggable,
    ImageUploader,
    WidgetImageGalleryRows
  },

  props: {
    widgetData: {
      type: Object,
      required: false
    },
    playerProfileData: {
      type: Array,
      required: false
    }
  },

  data: () => ({
    dialogImageGalleryWidget: false,
    dialogDelete: false,
    dialogListWidget: null,
    imageUploadSession: {
      gallery: []
    },
  }),


  mounted() {
    console.log("dialogImageGalleryWidget Mounted");

    if (this.widgetData) {
      this.imageUploadSession = JSON.parse(JSON.stringify(this.widgetData))
    } else if (this.playerProfileData) {
      this.imageUploadSession.gallery = this.playerProfileData
    }
  },

  methods: {
    addImage(payload) {
      this.imageUploadSession.gallery.push(payload);
    },

    postImages() {
      console.log("Posting images...", this.imageUploadSession);
      this.$emit("imageArrayUploaded", this.imageUploadSession);
    },

    deleteGalleryItem(index){
      console.log("Deleting...", index);
      this.imageUploadSession.gallery.splice(index, 1);
      console.log(this.imageUploadSession);
    }
  }
}
</script>

<style lang="scss" scoped>
  .draggable-item {
    transition: all 0.5s, opacity 0.2s;
  }

  .single-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
    border-top: 1px solid #ebebeb;

    &__preview {
      margin: 10px 40px 0 30px;
      max-height: 80px;
      max-width: 80px;
    }
  }

  .preview-list {
    border-bottom: 1px solid #DFE3E9;
  }

  .preview {
    display: block;
    margin: 0;
    padding: 15px 0 10px;
    border-top: 1px solid #DFE3E9;

    .v-list__tile {
      padding: 0;
    }

    &__label {
      padding: 0;
      margin: 0 0 10px;
      font-size: 10px;
    }

    &__image {
      max-height: 56px;
      max-width: 56px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &__action {
      display: block;
      margin: 0 0 0 30px;
      cursor: pointer;
    }
  }

  .uploader {
    margin: 0 0 40px 0;

    &__heading {
      margin: 0 0 20px;
      color: #24002F;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    &__upload-input {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      white-space: nowrap;
      width: 1px;
    }

    &__upload-button {
      cursor: pointer;
    }

    &__ctas {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &__droparea {
      width: 100%;
      padding: 50px;
      text-align: center;
      border: 2px dashed $lightgrey;
      background-color: $lightergrey;
      margin-top: 20px;
      span {
        color: $lightPurple;
      }
    }
  }

  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }

</style>
