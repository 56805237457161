<script>
export default [
  "Goalkeeper",
  "Full-Back",
  "Wing-Back",
  "Central Defender/Centre Back",
  "Defensive Midfield",
  "Central Midfield",
  "Wide Midfield/Winger",
  "Striker/Centre Forward"
]
</script>
