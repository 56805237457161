<template>
  <ul class="image-gallery-widget__simple-list image-gallery-widget__simple-list--main draggable-item">
    <li>
      <ul class="image-gallery-widget__simple-list">
          <li class="image-gallery-widget__simple-list-preview" :style="`background-image: url('${this.previewImage}');`"></li>
          <li class="image-gallery-widget__simple-list">
            <ul class="image-gallery-widget__simple-list-items">
              <li class="image-gallery-widget__simple-list-item">Title: {{this.name}}</li>
            </ul>
          </li>
      </ul>
    </li>
    <li class="image-gallery-widget__simple-list-actions">
      <button class="image-gallery-widget__simple-list-action" type="button" @click="deleteGalleryItem()">
        <v-icon>delete</v-icon>
      </button>
    </li>
  </ul>
</template>

<script>
import axios from "axios"

export default {
  name: "WidgetImageGalleryRows",

  props: {
    item: {
      type: Object,
      required: true
    },
  },

  data: function () {
    return {
      previewImage: null,
      name: null,
      fileType: null,
      savedTimestamp: null
    }
  },

  mounted() {
    this.fetchImage(this.item.mediaLibraryID)
    console.log("WidgetImageGalleryRows mounted");
    console.log(this.item);
  },

  methods: {
    fetchImage(mediaLibraryID) {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url:`${process.env.VUE_APP_BASEURL}/media/${mediaLibraryID}`,
          headers: { "Authorization": jwt },
        }).then(response => {
          console.log(response);
          this.previewImage = `${response.data.imageHandlerURL}/fit-in/200x200/${response.data.body.key}`;
          this.name = response.data.body.name;
          this.fileType = response.data.body.fileType;
          this.savedTimestamp = response.data.body.savedTimestamp;
        })
        .catch(e => {
          console.error("Error fetching image:", e);
        });
      })
    },
    deleteGalleryItem(){
      this.$emit("delete");
    }
  }
}
</script>

<style lang="scss" scoped>

.image-gallery-widget {
  &__simple-list {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--main {
      padding: 20px;
      width: 100%;
      border-bottom: 1px solid $lightergrey;
    }
    &-preview {
      float: left;
      width: 64px;
      height: 64px;
      min-width: 64px;
      min-height: 64px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border: 3px solid $grey;
    }
    &-item {
      color: $lightPurple;
      &s {
        padding: 0 20px;
        flex-direction: column;
      }
    }
    &-actions {
      display: flex;
      justify-content: flex-end;
    }
    &ings {
      border-top: 1px solid $lightergrey;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
